import api from './AxiosConfig';

export const getScenes = (videoId, modelId, onSceneReceived) => {
  const ws = new WebSocket(
      `${process.env.REACT_APP_WS_API_URL}/v1/film-wizard/video/${videoId}/scenes`
  );

  let isClosed = false;

  const closeWebSocket = () => {
    if (!isClosed) {
      isClosed = true;
      ws.close();
    }
  };

  // Open WebSocket connection and send the initial payload
  ws.onopen = () => {
    ws.send(
        JSON.stringify({
          model_id: modelId,
        })
    );
  };

  // Handle messages
  ws.onmessage = (event) => {
    const data = JSON.parse(event.data);

    if (data.status === "error") {
      console.error("WebSocket error:", data.detail);
      closeWebSocket();
      return;
    }

    if (data.status === "heartbeat") {
      console.log("Received heartbeat from server.");
      return;
    }

    if (data.video && data.video.scenes) {
      onSceneReceived(data.video);
    }
  };

  // Handle errors
  ws.onerror = (error) => {
    console.error("WebSocket error:", error);
    closeWebSocket();
  };

  // Handle WebSocket closure
  ws.onclose = () => {
    console.log("WebSocket connection closed.");
    isClosed = true;
  };

  // Cleanup function for when the component unmounts
  return () => {
    closeWebSocket();
  };
};

export const getScene = async (videoId, sceneId, modelId) => {
  try {
    const response = await api.put(`/v1/film-wizard/video/${videoId}/scene`, {
      "scene_index": sceneId,
      "model_id": modelId
    });
    return response.data;
  } catch (error) {
    console.error('Error in fetchVideoUrl:', error);
    return null;
  }
};

export const generateFinalClip = async (videoId) => {
  try {
    const response = await api.post(`/v1/film-wizard/video/${videoId}`);
    return response.data;
  } catch (error) {
    console.error('Error in fetchVideoUrl:', error);
    return null;
  }
};