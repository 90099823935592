import React, { useState, useRef, useEffect } from "react";
import AestheticsSelector from "./aesthetics/AestheticsSelector";
import AestheticSkeleton from "./aesthetics/AestheticsLoader";
import FrameViewer from "./generated-frames/FrameViewer";
import VideoViewer from "./wizard/VideoViewer";
import FinalVideoViewer from "./wizard/FinalVideoViewer";
import { getFrames } from "../services/ImageService";
import { generateFinalClip, getScenes } from "../services/VideoService";
import {
  getAuthenticatedUserId,
  loginAndStoreUser,
} from "../services/UserService";
import { getModels } from "../services/ModelService";
import LoginModal from "./LoginModal";

// Define STEPS outside the component
const STEPS = {
  AESTHETICS_SELECTOR: "AESTHETICS_SELECTOR",
  FRAME_VIEWER: "FRAME_VIEWER",
  VIDEO_VIEWER: "VIDEO_VIEWER",
  FINAL_VIDEO_VIEWER: "FINAL_VIDEO_VIEWER",
};

const Wizard = ({ text, selectedVideo = null }) => {
  const [frames, setFrames] = useState([]);
  const [scenes, setScenes] = useState([]);
  const [aesthetics, setAesthetics] = useState([]);
  const [loadingAesthetics, setLoadingAesthetics] = useState(false);
  const [activeStep, setActiveStep] = useState(null); // Centralized state for the active step
  const [lastGeneratedVideoUrl, setLastGeneratedVideoUrl] = useState("");
  const [isWebSocketFrames, setIsWebSocketFrames] = useState(false);
  const [isWebSocketScenes, setIsWebSocketScenes] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [modelId, setModelId] = useState("");
  const wizardRef = useRef(null);
  const [error, setError] = useState("");
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const goToStep = (step) => {
    setActiveStep(step);

    // Scroll into view for smooth transition
    if (wizardRef.current) {
      wizardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleBack = () => {
    if (activeStep === STEPS.VIDEO_VIEWER) {
      setScenes([]); // Clear scenes data to avoid conflicts
      const updatedFrames =
        selectedVideo?.frames?.map((frame) => ({
          ...frame,
          takes:
            frame.takes?.length > 0
              ? frame.takes
              : [{ url: frame.url, caption: frame.caption }],
        })) || [];
      setFrames(updatedFrames);
      goToStep(STEPS.FRAME_VIEWER);
    } else if (activeStep === STEPS.FINAL_VIDEO_VIEWER) {
      setLastGeneratedVideoUrl(""); // Clear final video URL
      setScenes(selectedVideo?.scenes || []); // Set scenes from the selected video if available
      goToStep(STEPS.VIDEO_VIEWER);
    } else if (activeStep === STEPS.FRAME_VIEWER) {
      setFrames([]); // Clear frames data to avoid conflicts
      goToStep(STEPS.AESTHETICS_SELECTOR);
    }
  };

  useEffect(() => {
    if (selectedVideo) {
      setVideoId(selectedVideo.id);
      setModelId(selectedVideo.frames[0].takes[0].generated_by_model_id)

      if (selectedVideo.url && selectedVideo.url.trim()) {
        setLastGeneratedVideoUrl(selectedVideo.url);
        goToStep(STEPS.FINAL_VIDEO_VIEWER);
      } else if (selectedVideo.scenes?.length > 0) {
        setScenes(selectedVideo.scenes);
        goToStep(STEPS.VIDEO_VIEWER);
      } else if (selectedVideo.frames?.length > 0) {
        setFrames(selectedVideo.frames);
        goToStep(STEPS.FRAME_VIEWER);
      }
    }
  }, [selectedVideo, selectedVideo?.key]);

  useEffect(() => {
    if (text.trim()) {
      const fetchModels = async () => {
        wizardRef.current.scrollIntoView({ behavior: "smooth" });
        try {
          setLoadingAesthetics(true);
          const models = await getModels(text.trim());
          setAesthetics(models);
          goToStep(STEPS.AESTHETICS_SELECTOR);
          setLoadingAesthetics(false);
        } catch (err) {
          setError("Failed to fetch models. Please try again.");
          setLoadingAesthetics(false);
        }
      };
      fetchModels();
    }
  }, [text]);

  const handleAestheticSelect = async (aesthetic) => {
    setActiveStep(STEPS.FRAME_VIEWER);
    setModelId(aesthetic.id);
    setError("");
    setIsWebSocketFrames(true);

    const firebaseUid = await getAuthenticatedUserId();

    getFrames(text, aesthetic.id, firebaseUid, (video) => {
      if (video.frames && video.frames.length > 0) {
        setVideoId(video.id);

        setFrames((prevFrames) => {
          const frameMap = new Map(
            prevFrames.map((frame) => [frame.position, frame])
          );

          // Merge incoming frames with existing frames based on position
          video.frames.forEach((frame) => {
            const updatedFrame = {
              ...frame,
              takes:
                frame.takes?.length > 0
                  ? frame.takes
                  : [{ url: frame.url, caption: frame.caption }],
            };
            frameMap.set(frame.position, updatedFrame);
          });

          // Return an array sorted by position
          return Array.from(frameMap.values()).sort(
            (a, b) => a.position - b.position
          );
        });
      }
    });
  };

  const handleConfirmFrames = async () => {
    const firebaseUid = await getAuthenticatedUserId();

    if (!firebaseUid || firebaseUid.trim() === "") {
      setIsLoginModalOpen(true);
      return;
    }

    setActiveStep(STEPS.VIDEO_VIEWER);
    setIsWebSocketScenes(true);

    getScenes(videoId, modelId, (video) => {
      if (video.scenes && video.scenes.length > 0) {
        if (video.scenes.every((scene) => scene.url === "")) {
          setScenes(video.scenes);
        } else if (video.scenes.length === 1) {
          setScenes((prevScenes) =>
            prevScenes.map((scene) =>
              scene.position === video.scenes[0].position
                ? { ...scene, ...video.scenes[0] }
                : scene
            )
          );
        }
      }
    });
  };



  const handleGenerateVideo = async () => {
    setActiveStep(STEPS.FINAL_VIDEO_VIEWER);
    try {
      const response = await generateFinalClip(videoId);
      setLastGeneratedVideoUrl(response.url);
      wizardRef.current.scrollIntoView({ behavior: "smooth" });
    } catch (err) {
      setError("Failed to generate video. Please try again.");
    }
  };

  const handleDownloadVideo = () => {
    fetch(lastGeneratedVideoUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "final-clip.mp4";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error fetching the file:", error));
  };

  const handleLogin = async () => {
    setIsLoginModalOpen(false);

    try {
      await loginAndStoreUser(videoId);
      handleConfirmFrames();
    } catch (error) {
      console.error("Login failed:", error);
      setError("Login was unsuccessful. Please try again.");
    }
  };

  const closeLoginModal = () => setIsLoginModalOpen(false);

  return (
    <>
      <section ref={wizardRef} className='wizard-container mb-16'>
        {activeStep === STEPS.AESTHETICS_SELECTOR && (
          <section className='container mx-auto px-4'>
            <div className='max-w-5xl mx-auto grid gap-6 md:gap-8'>
              <div className='text-center'>
                <h2 className='text-xl md:text-3xl font-semibold mb-3'>
                  What aesthetics suits your film the best?
                </h2>
                <p className='text-base text-muted-foreground'>
                  Choose a style that best matches your vision. This will guide
                  our AI in creating the perfect frames for your video.
                </p>
              </div>
              {loadingAesthetics ? (
                <AestheticSkeleton />
              ) : (
                <AestheticsSelector
                  // selected={selectedAesthetic}
                  onSelect={handleAestheticSelect}
                  aesthetics={aesthetics}
                />
              )}
            </div>
          </section>
        )}

        {activeStep === STEPS.FRAME_VIEWER && (
          <section className='container mx-auto px-4'>
            <div className='mx-auto grid gap-6 md:gap-8'>
              {/* <h2 className='text-xl md:text-3xl font-semibold text-center'>
                Generated Frames
              </h2> */}
              <FrameViewer
                  frames={frames}
                  onConfirm={handleConfirmFrames}
                  onBack={handleBack}
                  videoId={videoId}
                  modelId={modelId}
                  isWebSocket={isWebSocketFrames}
              />
            </div>
          </section>
        )}

        {activeStep === STEPS.VIDEO_VIEWER && (
          <VideoViewer
            scenes={scenes}
            onBack={handleBack}
            onGenerateVideo={handleGenerateVideo}
            videoId={videoId}
            modelId={modelId}
            isWebSocket={isWebSocketScenes}
          />
        )}

        {activeStep === STEPS.FINAL_VIDEO_VIEWER && (
          <FinalVideoViewer
            videoUrl={lastGeneratedVideoUrl}
            onBack={handleBack}
            onDownload={handleDownloadVideo}
          />
        )}
      </section>

      {error && <p className='text-red-500 mt-4 text-center'>{error}</p>}

      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={closeLoginModal}
        onLogin={handleLogin}
      />
    </>
  );
};

export default Wizard;
